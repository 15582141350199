import React, {useEffect, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { MDBDataTable } from 'mdbreact';
import ListItem from "./ListItem";
import {firebaseAnalytics} from '../config/config'
import AddLocationRoundedIcon from '@material-ui/icons/AddLocationRounded';
import {useDispatch, useSelector} from "react-redux";
import api from '../../utils/api';
import {setFeedback, setInpLocation} from "../../actions/index"
import LocationHelp from "./helpers/LocationHelp";
import LocationModal from "./modals/LocationModal";
import AddingRestHelp from "./helpers/AddingRestHelp";
import AddingRestModal from "./modals/AddingRestModal";
import FeedbackModal from "./modals/FeedbackModal";
import CopyrightNotice from "react-copyright-notice-component";


function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}

function List(props) {

    const inpLocation = useSelector((state => state.inpLocation));
    const feedback = useSelector((state => state.feedback));
    const [geoLocation, setGeoLocation] = useState(null);
    const [inpValue, setInpValue] = useState(inpLocation);
    const [inpLocationModalValue, setInpLocationModalValue] = useState('');
    const [restaurants, setRestaurants] = useState([]);
    const [isFocusInput, setFocusInput] = useState(false);
    const [isLocationModal, setLocationModal] = useState(false);
    const [isAddingRestModal, setAddingRestModal] = useState(false);
    const [isFeedbackModal, setFeedbackModal] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
        SlideTransition,
    });
    const [helpGeo, setHelpGeo] = useState('');
    const [helpAddingRest, setHelpAddingRest] = useState('');

    const dispatch = useDispatch();

    const { vertical, horizontal, open, message } = snackbarState;
    const showSnack = newState => {
        setSnackbarState({ open: true, ...newState })
    };
    const hideSnack = () => {
        setSnackbarState({ ...snackbarState, open: false})
    };

    useEffect(() => {
        getGeo();
    }, []);
    useEffect(() => {
        if((inpValue.length >= 2 || inpLocationModalValue) && !isFocusInput){
            dispatch(setInpLocation(inpValue));
            getRestaurants(inpValue, "input");
        }
    }, [inpValue]);
    useEffect(() => {
        if(geoLocation && !inpValue){
            getRestaurants(geoLocation, "geo");
        }
    }, [geoLocation]);

    useEffect(() => {
        if(feedback && restaurants.length !== 0){
            dispatch(setFeedback(false));
            takeFeedback();
        }
    }, [restaurants]);

    const getGeo = () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                let lat = position.coords.latitude.toFixed(4);
                let lng = position.coords.longitude.toFixed(4);
                setGeoLocation({lat: lat, lng: lng});
                {/*showSnack({
                    vertical: 'top',
                    horizontal: 'right',
                    message: `lat:${lat} 
                                lon:${lng}`,
                });*/}
            },
            error => {
                console.log(error);
                setHelpGeo(<LocationHelp getGeo={getGeo}/>);
                if(!inpValue){
                    toggleModal('location')
                }
            }
        );
    };
    const getRestaurants = async (info, source) => {
        if(source === "geo"){
            try{
                let data = await api.get(`?key=AIzaSyCnCzq7oxerLrwjI9thW1vPtTc-SU_pIHQ&lat=${info.lat}&lng=${info.lng}`, {});
                firebaseAnalytics.logEvent('getRestaurants');
                console.log("geoData: ", data);
                setRestaurants(data.data);
                setHelpAddingRest(<AddingRestHelp toggleModal={toggleModal}/>);
            }catch (e) {
                console.log(e)
            }
        } else if(source === "input"){
            try{
                let data = await api.get(`?key=AIzaSyCnCzq7oxerLrwjI9thW1vPtTc-SU_pIHQ&zipcode=${info}`, {});
                firebaseAnalytics.logEvent('getRestaurants');
                console.log("inpData: ", data);
                setRestaurants(data.data);
                setHelpAddingRest(<AddingRestHelp toggleModal={toggleModal}/>);
            }catch (e) {
                console.log(e)
            }
        }
    };
    const changeInputHandler = event => {
        setInpValue(event.target.value);
        setInpLocationModalValue('');
    };
    const toggleFocus = () => {
        setFocusInput(!isFocusInput);
    };
    const toggleModal = (modal) => {
        if(modal === "location"){
            setLocationModal(!isLocationModal);
        } else if(modal === "addRestaurant"){
            setAddingRestModal(!isAddingRestModal)
        } else if(modal === "feedback"){
            setFeedbackModal(!isFeedbackModal)
        }
    };

    const takeFeedback = () => {
        setTimeout(() => {
            if(!isAddingRestModal) setFeedbackModal(!isFeedbackModal)
        }, 20000)
    };

    const submitMainInput = e => {
        e.preventDefault();
        if(inpValue){
            dispatch(setInpLocation(inpValue));
            getRestaurants(inpValue, "input");
        }

    };

    const submitLocationModal = value => {
        setInpLocationModalValue(value);
        setInpValue(value);
        toggleModal("location");
    };
    const submitAddingRestModal = async obj => {
        try {
            const response = await api.post(`suggest?key=AIzaSyCnCzq7oxerLrwjI9thW1vPtTc-SU_pIHQ`, obj);
            if(response.status === 200) console.log("suggest: ", obj);
        } catch (e) {
            console.error(`😱 Axios request failed: ${e}`);
        }
        toggleModal("addRestaurant");
        showSnack({
            vertical: 'top',
            horizontal: 'right',
            message: 'Thank you!'
        });
        setTimeout(hideSnack, 2000)
    };
    const submitFeedbackModal = async obj => {
        try {
            const response = await api.post(`feedback?key=AIzaSyCnCzq7oxerLrwjI9thW1vPtTc-SU_pIHQ`, obj);
            if(response.status === 200) console.log("feedback: ", obj);
        } catch (e) {
            console.log(`😱 Axios request failed: ${e}`);
        }
        toggleModal("feedback");
        showSnack({
            vertical: 'top',
            horizontal: 'right',
            message: 'Thank you!'
        });
        setTimeout(hideSnack, 2000)
    };

    const data4Table = () => {
        let currentRestaurants = restaurants;
        currentRestaurants.forEach(rest => {
            rest.listItem = <ListItem restaurant={rest} key={rest.resource.id}/>
        });
        return{
            columns: [
                {
                    label: "Main",
                    field: "listItem"
                }
            ],
            rows: currentRestaurants,
            translations: {
                add: 'Add'
            }
        }
    };
    const getList = () => {
        if (restaurants.length > 20) {
            return (
                <MDBDataTable
                    entries={20}
                    searching={false}
                    data={data4Table()}
                />
            )
        } else if(restaurants.length > 0){
            return(
                restaurants.map(rest => {
                    return <ListItem restaurant={rest} key={rest.resource.id}/>
                })
            )
        } else return ""
    };

    const getHelpers = () => {
        if((inpValue && restaurants.length === 0) || (geoLocation && restaurants.length === 0)){
            return helpAddingRest
        } else if(!geoLocation && restaurants.length === 0){
            return helpGeo
        }
    };

    return (
        <div>
            <form onSubmit={submitMainInput}>
                <div className="inpWrap">
                    <div className={isFocusInput ? "inpBorder inpFocused" : "inpBorder"}>
                        <div className="mapIconWrap">
                            <AddLocationRoundedIcon style={isFocusInput ? {color: 'rgba(255, 255, 255, 1)'} : {color: 'rgba(255, 255, 255, 0.8)'}}/>
                        </div>
                        <input
                            type="text"
                            placeholder="Enter your location (zipcode or city)"
                            value={inpValue}
                            name="inpValue"
                            onChange={changeInputHandler}
                            onFocus={toggleFocus}
                            onBlur={toggleFocus}
                        />
                    </div>
                </div>
            </form>
            <div className="listWrap">
                <div className="content_wrap">
                    { getList() }
                    { getHelpers() }

                    <div style={{fontSize: "14px", color: "white"}}>
                        <CopyrightNotice copyrightHolder="contact@hindutemplesofusa.org"/>
                    </div>
                </div>
                <LocationModal
                    isLocationModal={isLocationModal}
                    toggleModal={() => toggleModal("location")}
                    submitModal={submitLocationModal}
                />
                <AddingRestModal
                    isAddingRestModal={isAddingRestModal}
                    toggleModal={() => toggleModal("addRestaurant")}
                    submitModal={submitAddingRestModal}
                />
                <FeedbackModal
                    isFeedbackModal={isFeedbackModal}
                    toggleModal={() => toggleModal("feedback")}
                    submitModal={submitFeedbackModal}
                />
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    key={`${vertical},${horizontal}`}
                    open={open}
                    onClose={hideSnack}
                    message={message}
                    TransitionComponent={SlideTransition}
                />



            </div>



        </div>
    );
}

export default List;
