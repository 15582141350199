import {combineReducers} from "redux";
import inpLocationReducer from "./inpLocationReducer"
import geoLocationReducer from "./geoLocationReducer";
import feedbackReducer from "./feedbackReducer";


const rootReducer = combineReducers({
    inpLocation: inpLocationReducer,
    geoLocation: geoLocationReducer,
    feedback: feedbackReducer
});


export default rootReducer;