import React,{Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import HomePage from "./components/HomePage";
import DetailPage from "./components/DetailPage";


const history = createBrowserHistory();

export default class App extends Component{

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/temple/:restaurantId" component={DetailPage}/>
                </Switch>
            </Router>
        );
    }
}

