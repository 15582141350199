import React from 'react';
import happy from "../../../assets/happy.svg";

export default function LocationHelp(props) {

    return(
        <div style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <img src={happy} alt="sorry" style={{width: "82px", color: '#F2F2F2'}}/>
            <div style={{
                margin: "25px 0",
                color: "#F2F2F2",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: 'center'
            }}>
                Please provide information about location.
            </div>
            <button className="calvera_btn"
                    onClick={props.getGeo}>
                My Location
            </button>
        </div>
    )
}