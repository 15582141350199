import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use


const config = {


    apiKey: "AIzaSyDjLJxudPdcYh8ms0eBtxjiexwmevTwDz8",
    authDomain: "calvera-app-cb603.firebaseapp.com",
    databaseURL: "https://calvera-app-cb603.firebaseio.com",
    projectId: "calvera-app-cb603",
    storageBucket: "calvera-app-cb603.appspot.com",
    messagingSenderId: "485127419397",
    appId: "1:485127419397:web:5733330956f87a8d4e8e6c",
    measurementId: "G-J1P8NMQVRW"
};

firebase.initializeApp(config);

// Initialize Firebase
export const firebaseAnalytics = firebase.analytics();
