import React, {useState} from 'react';
import {MDBModal, MDBModalBody} from "mdbreact";
import StarRatings from 'react-star-ratings';
import star from "../../../assets/premium-badge.svg";

export default function FeedbackModal(props) {

    const [rating, setRating] = useState(0);
    const [isCat1, setCat1] = useState(false);
    const [isCat2, setCat2] = useState(false);
    const [isCat3, setCat3] = useState(false);

    const changeRating = ( newRating ) => {
        setRating(newRating)
    };

    const handleCheckboxChange = e => {
        if(e.target.name === "isCat1"){
            setCat1(e.target.checked)
        } else if(e.target.name === "isCat2"){
            setCat2(e.target.checked)
        } else if(e.target.name === "isCat3"){
            setCat3(e.target.checked)
        }
    };

    return(
        <div className="feedbackModalWrapper">
            <MDBModal isOpen={props.isFeedbackModal} toggle={() => props.toggleModal("feedback")} centered>
                <MDBModalBody>
                    <div className="closeBtn">
                        <button type="button" className="close" aria-label="Close"
                            onClick={() => props.toggleModal("feedback")}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form onSubmit={ e => {
                        e.preventDefault();
                        let checkedCategories = [];
                        if(isCat1) checkedCategories.push('food');
                        if(isCat2) checkedCategories.push('events');
                        if(isCat3) checkedCategories.push('learning');
                        props.submitModal({rating: rating, categories: checkedCategories});
                    }}>
                        <div className="logoStar">
                            <img src={star} alt="sorry" style={{width: "42px", color: '#F2F2F2'}}/>
                        </div>

                        <div style={{fontSize: "14px"}}>
                            How do you rate this App (1 - 5) ?
                        </div>
                        <div className="starRating">
                            <StarRatings
                                rating={rating}
                                starRatedColor="#FF914D"
                                changeRating={changeRating}
                                numberOfStars={5}
                                name='rating'
                            />
                        </div>
                        <div style={{fontSize: "14px"}}>
                            Additional categories you would be interested in your community:
                        </div>
                        <div className="categories" style={{margin: '20px 0 25px'}}>

                            <div style={{fontSize: "14px"}} className="custom-control custom-checkbox">
                                <input
                                    id="events"
                                    name="isCat2"
                                    type="checkbox"
                                    checked={isCat2}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="custom-control-label" htmlFor="events">
                                    Community Events
                                </label>
                            </div>

                            <div style={{fontSize: "14px"}}  className="custom-control custom-checkbox">
                                <input
                                    id="learning"
                                    name="isCat3"
                                    type="checkbox"
                                    checked={isCat3}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="custom-control-label" htmlFor="learning">
                                    Education & Learning
                                </label>
                            </div>

                            <div style={{fontSize: "14px"}}  className="custom-control custom-checkbox">
                                <input
                                    id="food"
                                    name="isCat1"
                                    type="checkbox"
                                    checked={isCat1}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="custom-control-label" htmlFor="food">
                                    Food Vendors
                                </label>
                            </div>

                        </div>

                        <div style={{fontSize: "14px", fontWeight: 600, textAlign: "center", marginBottom: "20px"}}>
                            Appreciate your feedback!
                        </div>

                        <button
                            type="submit"
                            className="calvera_btn"
                            style={{width: "100%"}}
                        >
                            SUBMIT
                        </button>
                    </form>
                </MDBModalBody>
            </MDBModal>
        </div>
    )
}
