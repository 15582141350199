import React from 'react';

export default function BackGroundLayer(props) {

    return (
        <div style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#2A3035",
            zIndex: -1
        }}>
            <div
                style={{
                    width: "100%",
                    height: props.height || "15vh",
                    backgroundColor: "#0BA33B"
                }}
            > </div>
        </div>
    );

}
