import {
    GET_INPLOCATION,
    SET_INPLOCATION,
    GET_GEOLOCATION,
    SET_GEOLOCATION,
    SET_FEEDBACK
} from '../constants/action-types';


export function setInpLocation(payload) {
    return {type: SET_INPLOCATION, payload}
}

export function getInpLocation() {
    return {type: GET_INPLOCATION}
}

export function setGeoLocation(payload) {
    return {type: SET_GEOLOCATION, payload}
}

export function getGeoLocation() {
    return {type: GET_GEOLOCATION}
}

export function setFeedback(payload) {
    return {type: SET_FEEDBACK, payload}
}

