import React from 'react';
import BackGroundLayer from "../BackGroundLayer";
import Header from "./Header";
import RestaurantCard from "./RestaurantCard";
import CopyrightNotice from "react-copyright-notice-component";


export default function DetailPage(props) {

    return (
        <div className="componentWrap">
            <BackGroundLayer />
            <Header />
            <RestaurantCard props={props.location.aboutProps}/>

            <div style={{fontSize: "14px", color: "white", marginLeft : "20px"}}>
                <CopyrightNotice copyrightHolder="contact@hindutemplesofusa.org"/>
            </div>
            <br/><br/>
        </div>
    );


}
