import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo_final_small.png';
import message from "../../assets/sarvejana.png";

export default class TopHeader extends Component {

    render() {
        return (
            <div style={{
                paddingTop: "env(safe-area-inset-top)",
                width: "100vw",
                textAlign: "center",
                position: "relative",
                fontFamily:    "Impact",
                fontSize:      "18px",
                fontWeight:    "bold"
            }}>
                <Link to={{pathname: '/', calledFromDetail: true}}>
                    <FontAwesomeIcon icon={faChevronLeft} color="#FFFFFF"
                                     style={{
                                         fontSize: "27px",
                                         position: 'absolute',
                                         top: "30px",
                                         left: "5px"
                                     }}
                    />
                </Link>
                <img src={logo} alt="logo"
                     style={{padding: '15px 5px', maxHeight: '115px'}}
                />
                <img src={message} alt="message"
                     style={{padding: '15px 5px', maxHeight: '115px'}}
                />

            </div>
        )
    }
}
