import React, {Component} from 'react';
import Header from './Header';
import BackGroundLayer from "../BackGroundLayer";
import BottomShadow from "../BotttomShadow";
import List from "./List";




export default class HomePage extends Component {


    render() {
        return (
            <div className="componentWrap">
                <BackGroundLayer height='22vh'/>
                <Header />
                <List {...this.props}/>
                <BottomShadow/>
            </div>
        )
    }
}
