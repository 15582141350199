import React from 'react';

export default function BottomShadow(props) {

    return (
        <div style={{
            width: "100vw",
            height: 0,
            position: "absolute",
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 1,
            boxShadow: "0px 0px 26px 10px rgba(0,0,0,0.75)"
        }}>
        </div>
    );

}
