import React from 'react';
import vector from "../../../assets/Vector.png";

export default function AddingRestHelp(props) {

    return(
        <div style={{
            width: "100%",
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <img src={vector} alt="sorry" style={{width: "82px", color: '#F2F2F2'}}/>
            <div style={{
                margin: "25px 0",
                color: "#F2F2F2",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: 'center'
            }}>
                We’re sorry.<br/> We do not have information of Temples in your location.
            </div>
            <button className="calvera_btn"
                    onClick={() => props.toggleModal("addRestaurant")}>
                Help us Locate a Temple
            </button>
        </div>
    )
}
