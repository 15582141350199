import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPhone, faDonate } from '@fortawesome/free-solid-svg-icons';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import api from "../../utils/api";

import { ExternalLink } from 'react-external-link';

export default function ListItem(props) {

    const [like, setLike] = useState(<FavoriteBorderRoundedIcon style={{color: "rgb(255, 145, 77)", fontSize: "30px"}}/>);
    const [isLiked, setIsLiked] = useState(false);

    const likeHandler = async () => {
        if(!isLiked){
            try{
                let resp = await api.get(`/${props.restaurant.resource.id}/like?key=AIzaSyCnCzq7oxerLrwjI9thW1vPtTc-SU_pIHQ`, {});
                if(resp.status === 200) {
                    setLike(<FavoriteRoundedIcon style={{color: "rgb(255, 145, 77)", fontSize: "30px"}}/>);
                    setIsLiked(true)
                }
            }catch (e) {
                console.error(e)
            }
        }
    };

    return (
        <div className="listItemWrap">

                <div className="infoIconWrap">
                    <Link to={{
                        pathname: `/temple/${props.restaurant.resource.slug}`,
                        aboutProps: props.restaurant
                    }}>
                        <FontAwesomeIcon icon={faInfoCircle} color='#0BA33B'/>
                    </Link>
                </div>

                <div className="mainTextWrap">
                    <Link to={{
                        pathname: `/temple/${props.restaurant.resource.slug}`,
                        aboutProps: props.restaurant
                    }}>
                        <div className="itemName">
                            {props.restaurant.resource.name}
                        </div>
                    </Link>

                    <div className="itemAddress">
                        {props.restaurant.resource.physicalAddress.line1} <br/>
                        {props.restaurant.resource.physicalAddress.city}, {props.restaurant.resource.physicalAddress.state} {props.restaurant.resource.physicalAddress.zipcode}
                        <br/>
                    </div>
                    <div className="itemDistance">
                        <div style={{color: "saddlebrown"}}>{props.restaurant.distanceMiles} miles from your location</div>
                        <ExternalLink  href={`https://maps.google.com?q=${props.restaurant.resource.physicalAddress.line1}, ${props.restaurant.resource.physicalAddress.city}, ${props.restaurant.resource.physicalAddress.zipcode}`} >
                            <b><span>View on Google Maps</span></b>
                        </ExternalLink>
                    </div>
                    <div className="itemTime">
                        {/*<span>Open Now</span>*/}
                        {/*props.restaurant.resource.hoursOpen*/}
                    </div>
                </div>



            <div className="phoneWrap">
                <a href={`${props.restaurant.resource.contact.website}`} title={`Donate Now`} target={`_new`}>
                    <FontAwesomeIcon icon={faDonate} color='#F38841'/>
                </a>

            </div>


        </div>
    );


}
