import React, {useState} from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import InputMask from 'react-input-mask';

export default function AddingRestModal(props) {

    const [name, setNameValue] = useState('');
    const [city, setLocationValue] = useState('');
    const [contactPhone, setPhoneValue] = useState('');

    return(
        <div className="addingRestModalWrapper">
        <MDBModal isOpen={props.isAddingRestModal} toggle={() => props.toggleModal("addRestaurant")} centered>
            <MDBModalHeader toggle={() => props.toggleModal("addRestaurant")}>TEMPLE DETAILS</MDBModalHeader>
            <MDBModalBody>
                <form onSubmit={ e => {
                    e.preventDefault();
                    props.submitModal({name, city, contactPhone});
                }}>
                    <label htmlFor="name">Name of Temple<sup>*</sup></label>
                    <div className="inpModalWrap">
                        <div className="inpModalBorder">
                            <input
                                type="text"
                                value={name}
                                id="name"
                                onChange={e => {
                                    e.preventDefault();
                                    setNameValue(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <label htmlFor="location">Location City<sup>*</sup></label>
                    <div className="inpModalWrap">
                        <div className="inpModalBorder">
                            <input
                                type="text"
                                value={city}
                                id="location"
                                onChange={e => {
                                    e.preventDefault();
                                    setLocationValue(e.target.value )
                                }}
                            />
                        </div>
                    </div>
                    <label htmlFor="phone">Contact Phone<sup>*</sup></label>
                    <div className="inpModalWrap">
                        <div className="inpModalBorder">
                            <InputMask
                                mask="999-999-9999"
                                value={contactPhone}
                                onChange={e => {
                                    e.preventDefault();
                                    setPhoneValue(e.target.value)
                                }}
                                id="phone"
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="calvera_btn"
                        style={{width: "100%"}}
                    >
                        Submit
                    </button>
                </form>
            </MDBModalBody>
        </MDBModal>
        </div>
    )
}
