import {GET_INPLOCATION, SET_INPLOCATION} from '../constants/action-types';

const inpLocationReducer = (state = "", action) => {
    switch (action.type) {
        case SET_INPLOCATION: {
            state = action.payload;
            return state;
        }
        case GET_INPLOCATION: {
            return state;
        }
        default:
            return state;
    }
}

export default inpLocationReducer;