import React, {useEffect, useState} from 'react';
import faceBookIcon from '../../assets/faceBookIcon.png';
import Carousel from 'nuka-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDonate, faPhone} from '@fortawesome/free-solid-svg-icons';
import {firebaseAnalytics} from '../config/config';
import api from '../../utils/api';
import {ExternalLink} from "react-external-link";
import temple from '../../assets/atemple.jpg';


import { Map, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
    width: '100%',
    height: '100%'
};



export default function RestaurantCard(props) {

    // const id = props?.props?.detailURL.split('/')[4];
    const id = window.location.pathname.split('/')[2];
    const [restData, setRestData] = useState('');

    useEffect( () => {
        async function getData(){
            return await api.get(`/${id}?key=AIzaSyCnCzq7oxerLrwjI9thW1vPtTc-SU_pIHQ`, {});
        }
        getData().then(res => {
            setRestData(res.data);
            console.log(res.data)
        })
    }, []);

    let restaurant = restData;
    if(restaurant){
        firebaseAnalytics.logEvent('RestaurantCard ' + restaurant.resource.name);
        let images = [];
        restaurant.resource.imageURLs.forEach(img => {
            if(img.split(':')[0] === 'http'){
                let safeImg = `https:`;
                img.split(':').forEach(part => {
                     if(part !== img.split(':')[0])safeImg += part
                });
                images.push(safeImg)
            } else {
                images.push(img)
            }
        });
        let phones = () => {
          return restaurant.resource.contact.phone.map(phone => {
              return(
                  <div>
                      <a href={`tel:${phone.number}`}>
                          <FontAwesomeIcon icon={faPhone} style={{marginRight: '5px'}}/>
                          {phone.number}
                      </a>
                      ({phone.type})
                  </div>
              )
          })
        };
        return (
            <div className="RestaurantCardWrap">
                <div className="cardItem">
                    <div className="photoGallery">
                        <Carousel>
                            {images.map(img => {
                                        return (
                                            <img src={temple} alt="Temple" style={{maxWidth: "calc(100vw - 80px)", maxHeight: "180px"}}/>
                                        )
                                    })}
                        </Carousel>
                    </div>
                    <div className="restaurantName">
                        {restaurant.resource.name}
                    </div>

                    {/*
                    <div className="restaurantDescription">
                        {restaurant.resource.description}
                    </div>

                    */}

                    <div className="restaurantContactWrap">
                        <div className="contacts">
                            <div className="restaurantAddress">
                                {restaurant.resource.physicalAddress.line1}<br/>
                                {restaurant.resource.physicalAddress.city}, {restaurant.resource.physicalAddress.state} {restaurant.resource.physicalAddress.zipcode}

                                <br/>
                                <ExternalLink  href={`https://maps.google.com?q=${restaurant.resource.physicalAddress.line1}, ${restaurant.resource.physicalAddress.city}, ${restaurant.resource.physicalAddress.zipcode}`} >
                                    View on Google Maps
                                </ExternalLink>
                                <br/><br/>

                            </div>

                            <div style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                margin: "5px 0"
                            }}>
                                CONTACT:
                            </div>

                            <div className="phones">
                                <ExternalLink href={`${restaurant.resource.contact.website}`}>
                                    Visit Website
                                </ExternalLink>

                                <ExternalLink href={`${restaurant.resource.contact.website}`} title={`Donate Now`} target={`_new`}>
                                    <FontAwesomeIcon icon={faDonate} color='#F38841'/>
                                </ExternalLink>
                            </div>

                            <div className="phones">
                                {phones()}
                            </div>

                            {/*
                            <div className="phones">
                                <a href={`email:${restaurant.resource.contact.email}`}>
                                 Email: {restaurant.resource.contact.email}
                                </a>
                            </div>
                            */}

                        </div>
                        {/*<div className="social">
                            <div style={{marginTop: "10px", marginLeft: "auto"}}>
                                <a href={`${restaurant.resource.contact.social[0].url}`} >
                                    <img src={faceBookIcon} alt="faceBookIcon" style={{width: "30px"}}/>
                                </a>
                            </div>
                        </div>*/}
                    </div>

                    <div className="restaurantTime">
                        <span>Hours Open<sup>*</sup>: </span>
                        {restaurant.resource.hoursOpen}
                        <br/>

                    </div>


                    <div className="restaurantTime">
                        Looking to raise funds for your organization? Email us at:
                        <b><i> contact@hindutemplesofusa.org</i></b>
                        <br/><br/>

                        <b>* <i>Hours may differ due to COVID-19<br/></i></b>
                    </div>

                </div>



            </div>
        );
    } else return ''
}
