import {SET_FEEDBACK} from '../constants/action-types';

const feedbackReducer = (state = true, action) => {
    switch (action.type) {
        case SET_FEEDBACK: {
            state = action.payload;
            return state;
        }
        default:
            return state;
    }
}

export default feedbackReducer;