import React, {useState} from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import AddLocationRoundedIcon from "@material-ui/core/SvgIcon/SvgIcon";


export default function LocationModal(props) {

    const [inpModalValue, setInpModalValue] = useState('');

    const changeModalInputHandler = event => {
        setInpModalValue(event.target.value);
    };

    return(
        <div className="locationModalWrapper">
        <MDBModal isOpen={props.isLocationModal} toggle={() => props.toggleModal("location")} centered>
            <MDBModalHeader toggle={() => props.toggleModal("location")}>My Location</MDBModalHeader>
            <MDBModalBody>
                <form onSubmit={ e => {
                    e.preventDefault();
                    props.submitModal(inpModalValue);
                }}>
                    <div className="inpModalWrap">
                        <div className="inpModalBorder">
                            <div className="mapIconWrap">
                                <AddLocationRoundedIcon />
                            </div>
                            <input
                                type="text"
                                placeholder="Enter zip code or city"
                                value={inpModalValue}
                                name="inpModalValue"
                                onChange={changeModalInputHandler}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="calvera_btn"
                        style={{width: "100%"}}
                    >
                        Ok
                    </button>
                </form>
            </MDBModalBody>
        </MDBModal>
        </div>
    )
}