import React, {Component} from 'react';
import logo from '../../assets/logo_final_small.png';
import message from '../../assets/sarvejana.png';


export default class TopHeader extends Component {

    render() {
        return (
            <div style={{
                width: "100vw",
                textAlign: "center",
                fontFamily:    "Impact",
                fontSize:      "18px",
                fontWeight:    "bold"
            }}>
                <img src={logo} alt="logo"
                     style={{padding: '15px 5px', maxHeight: '115px'}}
                />
                <img src={message} alt="message"
                     style={{padding: '15px 5px', maxHeight: '115px'}}
                />



            </div>
        )
    }
}
