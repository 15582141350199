import {GET_GEOLOCATION, SET_GEOLOCATION} from '../constants/action-types';

const geoLocationReducer = (state = "", action) => {
    switch (action.type) {
        case SET_GEOLOCATION: {
            state = action.payload;
            return state;
        }
        case GET_GEOLOCATION: {
            return state;
        }
        default:
            return state;
    }
}

export default geoLocationReducer;